body {
  margin: 0;
  padding: 0;
  margin: 0;
  font-family: "Source Sans Pro, Arial, sans-serid";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: normal;
  src: url("assets/source-sans-pro-v14-latin-regular.eot"); /* IE9 Compat Modes */
  src: url("assets/source-sans-pro-v14-latin-regular.eot")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/source-sans-pro-v14-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("assets/source-sans-pro-v14-latin-regular.ttf")
      format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: bold;
  src: url("assets/source-sans-pro-v14-latin-600.eot"); /* IE9 Compat Modes */
  src: url("assets/source-sans-pro-v14-latin-600.eot")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/source-sans-pro-v14-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ url("assets/source-sans-pro-v14-latin-600.ttf")
      format("truetype"); /* Safari, Android, iOS */
}
@import "~react-image-gallery/styles/css/image-gallery.css";

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 30px;
  width: 15px;
}

.image-gallery-thumbnail-image {
  width: auto;
  height: 60px;
}

input[type=date]::-webkit-calendar-picker-indicator {
  margin-left: -15px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

